import { store } from '../app/store';

const bonusMap: { [key: string]: number } = {
  '0': 0,
  '1': 1,
  '2': 3,
  '3': 6,
  '4': 10,
  '5': 15,
  '6': 21,
};

export function exactRowCount(row: number): number {
  const score = store.getState().game.score;
  const card = store.getState().game.card;
  if (score.length === 0 || card === null) return 0;

  return score[row].filter((value, index) => card.rows[row][index].value === value).length;
}

export function getRowTotal(row: number): number {
  const score = store.getState().game.score;
  if (score.length === 0) return 0;

  let total = score[row].reduce((carry, value) => (carry || 0) + (value || 0), 0) || 0;
  total += bonusMap[exactRowCount(row)];
  return total;
}

export function getTotal(): number {
  const score = store.getState().game.score;
  if (score.length === 0) return 0;

  let total = 0;
  for(let i = 0; i < score.length; i++) {
    total += getRowTotal(i);
  }

  return total;
}
