import React from 'react';
import './Modal.scss';

type Props = {
    title?: string;
    onClose?: () => void;
    visible: boolean;
}

const Modal: React.FC<Props> = ({
    children,
    onClose,
    title,
    visible
}): JSX.Element => {
    if (!visible) {
        return <></>;
    }

    const close = () => {
        onClose?.();
    };

    return (
        <div className="modal">
            <div className="modal-background" onClick={close}/>
            <div className="modal-container">
                {title &&
                <div className="modal-title">{title}</div>
                }
                {children}
            </div>
        </div>
    );
};

export default Modal;
