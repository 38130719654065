enum Color {
  WHITE = 'white',
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  BLACK = 'black',
  YELLOW = 'yellow',
}

type Cell = {
  value: number;
  color: Color;
}

export type Card = {
  id: string;
  rows: Cell[][];
}

export const cards: Card[] = [
  {
    id: 'A',
    rows: [
      [
        {color: Color.RED, value: 6},
        {color: Color.YELLOW, value: 5},
        {color: Color.GREEN, value: 4},
        {color: Color.BLUE, value: 3},
        {color: Color.WHITE, value: 2},
        {color: Color.BLACK, value: 1},
      ],
      [
        {color: Color.YELLOW, value: 5},
        {color: Color.GREEN, value: 4},
        {color: Color.WHITE, value: 4},
        {color: Color.BLACK, value: 3},
        {color: Color.RED, value: 3},
        {color: Color.BLUE, value: 1},
      ],
      [
        {color: Color.BLUE, value: 6},
        {color: Color.RED, value: 5},
        {color: Color.BLACK, value: 3},
        {color: Color.WHITE, value: 2},
        {color: Color.GREEN, value: 2},
        {color: Color.YELLOW, value: 1},
      ],
      [
        {color: Color.BLUE, value: 5},
        {color: Color.YELLOW, value: 4},
        {color: Color.BLACK, value: 4},
        {color: Color.GREEN, value: 2},
        {color: Color.WHITE, value: 2},
        {color: Color.RED, value: 1},
      ],
      [
        {color: Color.BLUE, value: 4},
        {color: Color.YELLOW, value: 4},
        {color: Color.WHITE, value: 3},
        {color: Color.RED, value: 3},
        {color: Color.BLACK, value: 2},
        {color: Color.GREEN, value: 1},
      ],
    ],
  },
  {
    id: 'B',
    rows: [
      [
        {color: Color.BLUE, value: 6},
        {color: Color.BLACK, value: 5},
        {color: Color.WHITE, value: 4},
        {color: Color.YELLOW, value: 3},
        {color: Color.RED, value: 2},
        {color: Color.GREEN, value: 1},
      ],
      [
        {color: Color.BLACK, value: 5},
        {color: Color.BLUE, value: 4},
        {color: Color.RED, value: 4},
        {color: Color.YELLOW, value: 3},
        {color: Color.GREEN, value: 3},
        {color: Color.WHITE, value: 1},
      ],
      [
        {color: Color.WHITE, value: 6},
        {color: Color.GREEN, value: 5},
        {color: Color.YELLOW, value: 3},
        {color: Color.RED, value: 2},
        {color: Color.BLUE, value: 2},
        {color: Color.BLACK, value: 1},
      ],
      [
        {color: Color.GREEN, value: 5},
        {color: Color.RED, value: 4},
        {color: Color.WHITE, value: 4},
        {color: Color.BLACK, value: 2},
        {color: Color.YELLOW, value: 2},
        {color: Color.BLUE, value: 1},
      ],
      [
        {color: Color.BLACK, value: 4},
        {color: Color.RED, value: 4},
        {color: Color.BLUE, value: 3},
        {color: Color.GREEN, value: 3},
        {color: Color.WHITE, value: 2},
        {color: Color.YELLOW, value: 1},
      ],
    ],
  },
  {
    id: 'C',
    rows: [
      [
        {color: Color.WHITE, value: 6},
        {color: Color.BLUE, value: 5},
        {color: Color.RED, value: 4},
        {color: Color.GREEN, value: 3},
        {color: Color.BLACK, value: 2},
        {color: Color.YELLOW, value: 1},
      ],
      [
        {color: Color.BLUE, value: 5},
        {color: Color.WHITE, value: 4},
        {color: Color.YELLOW, value: 4},
        {color: Color.GREEN, value: 3},
        {color: Color.BLACK, value: 3},
        {color: Color.RED, value: 1},
      ],
      [
        {color: Color.RED, value: 6},
        {color: Color.BLACK, value: 5},
        {color: Color.GREEN, value: 3},
        {color: Color.YELLOW, value: 2},
        {color: Color.WHITE, value: 2},
        {color: Color.BLUE, value: 1},
      ],
      [
        {color: Color.YELLOW, value: 5},
        {color: Color.GREEN, value: 4},
        {color: Color.BLUE, value: 4},
        {color: Color.RED, value: 2},
        {color: Color.BLACK, value: 2},
        {color: Color.WHITE, value: 1},
      ],
      [
        {color: Color.WHITE, value: 4},
        {color: Color.BLUE, value: 4},
        {color: Color.RED, value: 3},
        {color: Color.YELLOW, value: 3},
        {color: Color.GREEN, value: 2},
        {color: Color.BLACK, value: 1},
      ],
    ],
  },
  {
    id: 'D',
    rows: [
      [
        {color: Color.GREEN, value: 6},
        {color: Color.RED, value: 5},
        {color: Color.BLACK, value: 4},
        {color: Color.WHITE, value: 3},
        {color: Color.YELLOW, value: 2},
        {color: Color.BLUE, value: 1},
      ],
      [
        {color: Color.RED, value: 5},
        {color: Color.YELLOW, value: 4},
        {color: Color.GREEN, value: 4},
        {color: Color.BLUE, value: 3},
        {color: Color.WHITE, value: 3},
        {color: Color.BLACK, value: 1},
      ],
      [
        {color: Color.BLACK, value: 6},
        {color: Color.WHITE, value: 5},
        {color: Color.BLUE, value: 3},
        {color: Color.GREEN, value: 2},
        {color: Color.YELLOW, value: 2},
        {color: Color.RED, value: 1},
      ],
      [
        {color: Color.BLACK, value: 5},
        {color: Color.BLUE, value: 4},
        {color: Color.YELLOW, value: 4},
        {color: Color.WHITE, value: 2},
        {color: Color.RED, value: 2},
        {color: Color.GREEN, value: 1},
      ],
      [
        {color: Color.YELLOW, value: 4},
        {color: Color.BLACK, value: 4},
        {color: Color.GREEN, value: 3},
        {color: Color.WHITE, value: 3},
        {color: Color.RED, value: 2},
        {color: Color.BLUE, value: 1},
      ],
    ],
  },
  {
    id: 'E',
    rows: [
      [
        {color: Color.YELLOW, value: 6},
        {color: Color.WHITE, value: 5},
        {color: Color.BLUE, value: 4},
        {color: Color.BLACK, value: 3},
        {color: Color.GREEN, value: 2},
        {color: Color.RED, value: 1},
      ],
      [
        {color: Color.WHITE, value: 5},
        {color: Color.BLACK, value: 4},
        {color: Color.BLUE, value: 4},
        {color: Color.RED, value: 3},
        {color: Color.YELLOW, value: 3},
        {color: Color.GREEN, value: 1},
      ],
      [
        {color: Color.GREEN, value: 6},
        {color: Color.YELLOW, value: 5},
        {color: Color.RED, value: 3},
        {color: Color.BLUE, value: 2},
        {color: Color.BLACK, value: 2},
        {color: Color.WHITE, value: 1},
      ],
      [
        {color: Color.WHITE, value: 5},
        {color: Color.BLACK, value: 4},
        {color: Color.RED, value: 4},
        {color: Color.BLUE, value: 2},
        {color: Color.GREEN, value: 2},
        {color: Color.YELLOW, value: 1},
      ],
      [
        {color: Color.RED, value: 4},
        {color: Color.GREEN, value: 4},
        {color: Color.YELLOW, value: 3},
        {color: Color.BLACK, value: 3},
        {color: Color.BLUE, value: 2},
        {color: Color.WHITE, value: 1},
      ],
    ],
  },
  {
    id: 'F',
    rows: [
      [
        {color: Color.BLACK, value: 6},
        {color: Color.GREEN, value: 5},
        {color: Color.YELLOW, value: 4},
        {color: Color.RED, value: 3},
        {color: Color.BLUE, value: 2},
        {color: Color.WHITE, value: 1},
      ],
      [
        {color: Color.GREEN, value: 5},
        {color: Color.RED, value: 4},
        {color: Color.BLACK, value: 4},
        {color: Color.WHITE, value: 3},
        {color: Color.BLUE, value: 3},
        {color: Color.YELLOW, value: 1},
      ],
      [
        {color: Color.YELLOW, value: 6},
        {color: Color.BLUE, value: 5},
        {color: Color.WHITE, value: 3},
        {color: Color.BLACK, value: 2},
        {color: Color.RED, value: 2},
        {color: Color.GREEN, value: 1},
      ],
      [
        {color: Color.RED, value: 5},
        {color: Color.WHITE, value: 4},
        {color: Color.GREEN, value: 4},
        {color: Color.YELLOW, value: 2},
        {color: Color.BLUE, value: 2},
        {color: Color.BLACK, value: 1},
      ],
      [
        {color: Color.GREEN, value: 4},
        {color: Color.WHITE, value: 4},
        {color: Color.BLACK, value: 3},
        {color: Color.BLUE, value: 3},
        {color: Color.YELLOW, value: 2},
        {color: Color.RED, value: 1},
      ],
    ],
  },
];
