import { Card } from '../components/cards';
import { GameAction } from '../actions/game';

export type GameState = {
  card: Card | null;
  score: (number|null)[][];
};

const defaultState: GameState = {
  card: null,
  score: [],
};

type ActionType = GameAction;

export default function gameReducer(state = defaultState, action: ActionType): GameState {
  switch (action.type) {
    case 'ACTION_GAME_SET_CARD':
      return { ...state, card: action.card || null };
    case 'ACTION_GAME_SET_SCORE':
      return { ...state, score: action.score ? [...action.score] : []}
  }

  return state;
}
