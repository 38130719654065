import React from 'react';
import { TwentyOne } from './components/TwentyOne';

function App() {
  return (
    <div className="App">
      <TwentyOne />
    </div>
  );
}

export default App;
