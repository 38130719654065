import { Component } from 'react';
import './ScoreModal.scss';
import Modal from './Modal';

type Props = {
    visible: boolean;
    onValueSelect: (value: number) => void;
    onClose: () => void;
};

export default class ScoreModal extends Component<Props> {
    private options = [0,1,2,3,4,5,6];

    render(): JSX.Element {
        return (
            <Modal title="Selecteer een waarde" visible={ this.props.visible } onClose={ () => this.props.onClose() }>
                <div className="options">
                    {
                        this.options?.map(option => {
                            return <div className="option button" onClick={ () => this.props.onValueSelect(option) } key={option}>{option}</div>;
                        })
                    }
                </div>
            </Modal>
        );
    }
}
