import React, { useCallback, useEffect, useState } from 'react';
import './TwentyOne.scss';
import ScoreModal from './ScoreModal';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { actionGameSetScore, actionSelectNextCard, actionGameLoadGame } from '../actions/game';
import { getRowTotal, getTotal } from './Game';

export const TwentyOne: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const card = useAppSelector(state => state.game.card);
  const score = useAppSelector(state => state.game.score);

  const [showTotal, setShowTotal] = useState(false);
  const [selectedCell, setSelectedCell] = useState<null | ({row: number, cell: number})>(null);
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    dispatch(actionGameLoadGame());
  }, [dispatch]);

  const selectCard = useCallback(() => {
    if (isStarted && !window.confirm('Weet je zeker dat je van kaart wilt wisselen?')) {
      return;
    }

    dispatch(actionSelectNextCard());
    setShowTotal(false);
  }, [isStarted, dispatch]);

  const setScore = useCallback((value: number) => {
    if (selectedCell === null || score === null) {
      return;
    }

    dispatch(actionGameSetScore(selectedCell.row, selectedCell.cell, value));
    setIsStarted(true);
    setSelectedCell(null);
  }, [dispatch, selectedCell, score]);

  return (
    <>
      <div className="card">
        { card?.rows.map((row, rowIndex) => (
          <div className="row" key={rowIndex}>
            { row.map((cell, cellIndex) => (
              <div className="cell" key={rowIndex + cell.color} onClick={() => setSelectedCell({row: rowIndex, cell: cellIndex})}>
                <div className={`dice dice-${cell.color} ${score[rowIndex][cellIndex] === 0 ? 'missed' : ''}`}>
                  <div className="dice-value">{ cell.value }</div>
                  { score[rowIndex][cellIndex] }
                </div>
                <input type="checkbox" checked={score[rowIndex][cellIndex] === card.rows[rowIndex][cellIndex].value} readOnly={true} />
              </div>
            )) }

            <div className="cell">
              <div className="total">{getRowTotal(rowIndex)}</div>
            </div>
          </div>
        )) }
        <div className="row">
          <div className="cell">Totaal</div>
          <div className="cell">
            <div
              className="total"
              style={{ backgroundColor: showTotal ? 'white' : 'black' }}
              onClick={() => setShowTotal(!showTotal) }
            >{getTotal()}</div>
          </div>
          <div style={{ flex: 1 }} />
          <div className="cell" onClick={selectCard}>Kaart {card?.id}</div>
        </div>
      </div>
      <ScoreModal visible={selectedCell !== null} onValueSelect={setScore} onClose={() => setSelectedCell(null)} />
    </>
  );
};
