import { Card, cards } from '../../components/cards';
import { AppThunk } from '../../app/store';

const CARD_LOCALSTORAGE_KEY = 'card';
const SCORE_LOCALSTORAGE_KEY = 'score';

type GameActionType =
  'ACTION_GAME_SET_CARD' |
  'ACTION_GAME_SET_SCORE'
;

export interface GameAction {
  type: GameActionType;
  card?: Card | null;
  score?: (number|null)[][] | null;
}

export function actionGameLoadGame(): AppThunk {
  return dispatch => {
    const card = localStorage.getItem(CARD_LOCALSTORAGE_KEY);
    const score = localStorage.getItem(SCORE_LOCALSTORAGE_KEY);

    if (card === null || score === null) {
      dispatch(actionGameSetupGame());
      return;
    }

    dispatch({
      type: 'ACTION_GAME_SET_CARD',
      card: JSON.parse(card),
    } as GameAction);

    dispatch({
      type: 'ACTION_GAME_SET_SCORE',
      score: JSON.parse(score),
    } as GameAction);
  };
}

export function actionGameSetupGame(): AppThunk {
  return (dispatch, getState) => {
    let card = getState().game.card;
    if (card === null) {
      card = selectNextCard(card);
    }

    const score = card.rows.map(row => row.map(() => null));
    localStorage.setItem(SCORE_LOCALSTORAGE_KEY, JSON.stringify(score));

    dispatch({
      type: 'ACTION_GAME_SET_SCORE',
      score,
    } as GameAction)

    dispatch(actionGameSaveCard(card));
  };
}

export function actionGameSetScore(row: number, cell: number, value: number): AppThunk {
  return (dispatch, getState) => {
    const score = getState().game.score;
    if (score === null) {
      return;
    }

    const newScore = Object.assign([...score], {
      [row]: Object.assign([...score[row]], {
        [cell]: value
      })
    });

    localStorage.setItem(SCORE_LOCALSTORAGE_KEY, JSON.stringify(newScore));

    dispatch({
      type: 'ACTION_GAME_SET_SCORE',
      score: newScore,
    } as GameAction);
  }
}

export function actionGameSaveCard(card: Card): GameAction {
  localStorage.setItem(CARD_LOCALSTORAGE_KEY, JSON.stringify(card));
  return {
    type: 'ACTION_GAME_SET_CARD',
    card,
  };
}

export function actionSelectNextCard(): AppThunk<Card> {
  return (dispatch, getState) => {
    const nextCard = selectNextCard(getState().game.card);

    dispatch(actionGameSaveCard(nextCard));
    dispatch(actionGameSetupGame());
    return nextCard;
  };
}

function selectNextCard(currentCard: Card|null): Card {
    let card = cards[0];

    if (currentCard === null) {
      return card;
    }

    let index = cards.findIndex(c => c.id === currentCard.id);
    if (index < 0) {
      return card;
    }

    index = index + 1 < cards.length ? index + 1 : 0;
    card = cards[index];

    return card;
}
